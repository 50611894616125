import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseTrendingMenuTheme from 'base/components/TrendingMenu/theme';

const Tag = tw.theme({
  extend: TagTheme,
  variants: {
    colors: {
      default: ['bg-white'],
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

const TrendingMenu = tw.theme({
  extend: BaseTrendingMenuTheme,
  slots: {
    base: ['bg-gray-100', 'px-0', 'wings', 'wings-gray-100', 'max-w-content', 'rounded-2xl', 'mx-auto'],
    headline: ['whitespace-nowrap', 'text-headline-3xs'],
    prefix: ['text-red-700'],
  },
});

export default Object.assign(TrendingMenu, { Tag });
