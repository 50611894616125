import { merge } from '@/utils/merge';
import { tw } from '@/utils/tw';
import { default as BaseTagTheme } from 'base/components/Tag/theme';

const Tag = tw.theme({
  extend: BaseTagTheme,
  base: ['uppercase', 'text-headline-3xs'],
  variants: merge(BaseTagTheme.variants, {
    colors: {
      default: [
        'bg-gray-100',
        'text-black',
        'hover:bg-gray-200',
        'focus:bg-gray-200',
        'focus:ring-blue-900',
        'active:bg-gray-300',
      ],
    },
    size: {
      small: ['h-7'],
      medium: ['h-9'],
      large: ['h-12'],
    },
    variant: {
      default: ['ring-offset-0', 'focus:ring-offset-2', 'focus:ring-2'],
    },
  }),
});

export default Tag;
