import { tw } from '@/utils/tw';
import BaseVideoBaseTheme from 'base/components/standalone/VideoBaseContent/theme';

const { CoverBox, TrendingMenu, Breadcrumbs } = BaseVideoBaseTheme;

export default Object.assign(
  tw.theme({
    base: ['flex', 'flex-col', 'mx-auto', 'max-w-content', 'gap-grid'],
  }),
  {
    CoverBox,
    TrendingMenu,
    Breadcrumbs,
  },
);
