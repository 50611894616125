import { tw } from '@/utils/tw';
import { default as BaseCoverBox } from 'base/components/CoverBox/theme';

const Prose = BaseCoverBox.Prose;

const CoverBox = tw.theme({
  extend: BaseCoverBox,
  slots: {
    base: ['bg-white', 'wings-none', 'p-grid-m', 'rounded-2xl'],
    headline: ['text-headline-xl', 'sm:text-headline-2xl'],
    toggle: ['bg-white', '!font-bold', 'text-blue-900', 'text-body-xs', 'sm:text-body-sm'],
  },
});

export default Object.assign(CoverBox, { Prose });
